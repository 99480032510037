import { Routes, Route, BrowserRouter, useLocation, Router } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Download from './pages/Download';
import Footer from './components/Footer';
import Documentation from './pages/Documentation';

function App() {
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  const page = (pathname.length > 0) ? pathname + "-page" : "home-page"

  return (
    <div className={page}>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="download" element={<Download />} />
        <Route path="docs" element={<Documentation />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
