import Gallery from "../components/Gallery";
import GettingStarted from "../components/GettingStarted";
import WelcomeBanner from "../components/WelcomeBanner";
import './Home.css';

function Home() {
    return (
        <div id="home">
            <WelcomeBanner />
            <GettingStarted />
            <Gallery />
        </div>
    );
}

export default Home;