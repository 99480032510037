import { Container, Row, Col } from "react-bootstrap";
import './Gallery.css';

function Gallery() {
    return (
        <div className="gallery">
            <Container>
                <Row>
                    <Col>
                        <img src="gallery/Beauty2-Web.png"></img>
                        <img src="gallery/wararuarnold.png"></img>
                        <img src="gallery/still_life.jpg"></img>
                    </Col>
                    <Col>
                        <img src="gallery/Glenlevet_Arnold.png"></img>
                        <img src="gallery/TestRender2.webp"></img>
                        <img src="gallery/dalek.png"></img>
                    </Col>
                    <Col>
                        <img src="gallery/sundaepc.png"></img>
                        <img src="gallery/Arnold_v02.png"></img>
                        <img src="gallery/intenso.jpg"></img>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Gallery;