import { Button, Col, Container, Row } from "react-bootstrap";
import './Download.css';

function Download() {
    return (
        <div id="download" className="page">
            <Container>
                <h1>Downloads</h1>
                <div class="outline-container">
                    <h2>BtoA 0.5.0</h2>
                    <p>The latest development version of BtoA. We recommend downloading from here over the GitHub releases page &mdash; a lot has happened since the latest stable release but we're not quite ready to lock in the next version yet.</p>
                    <Button href="https://github.com/lunadigital/btoa/archive/refs/heads/dev.zip">Download</Button>
                </div>

                <Row>
                    <h3>Installation Instructions</h3>

                    <Col>
                        <div className="video-container">
                            <iframe src="https://www.youtube.com/embed/BLrZIc_prfo?si=8NTraP0ekNhoWjoZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </Col>

                    <Col>
                        <ol>
                            <li>Download the latest version of BtoA above. This will save a zip file to your Downloads folder.</li>
                            <li>Install the add-on in Blender by going to <span className="bold">Edit &gt; Preferences... &gt; Add-ons</span> and clicking the "Install" button. Use the pop-up window to find the BtoA zip file.</li>
                            <li>Once installed, expand the add-on preferences and click the "Install Arnold" button. This will install the latest compatible version of Arnold to your computer.</li>
                            <li>Restart Blender and get rendering!</li>
                        </ol>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Download;