import { Button, Col } from "react-bootstrap";
import './CTA.css';

function CTA({title, content, button_text, link}) {
    return (
        <Col className="cta">
            <h4>{title}</h4>
            <p>{content}</p>
            <Button href={link}>
                {button_text}
            </Button>
        </Col>
    );
}

export default CTA;