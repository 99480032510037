import { Container, Row, Col } from "react-bootstrap";
import CTA from "./CTA";

function GettingStarted() {
    return (
        <div id="getting-started" className="light-theme gradient">
            <Container>
            <Row style={{marginBottom: `2rem`}}>
                <CTA
                title="Download"
                content="BtoA is open-source and completely free to download.* Grab the latest release on GitHub or try the latest features on a development branch."
                button_text="Download BtoA"
                link="/download"
                />
                <CTA
                title="Learn"
                content="Our docs have everything you need to get up and running with BtoA, from installation to first render and beyond. Get started today!"
                button_text="Go to Docs"
                link="/docs"
                />
                <CTA
                title="Connect"
                content="Whether you need additional support, would like to get involved, or just want to hang out with cool people like you, check out our community on Discord."
                button_text="Join Discord"
                link="https://discord.com/invite/4QYv3vMGxS"
                />
            </Row>
            <Row>
                <p class="small">*Arnold is a trademarked product of Autodesk, Inc. and isn't officially associated with the Arnold for Blender project. Rendering in Arnold without a watermark requires a valid license from Autodesk. For more information, visit the Autodesk website.</p>
            </Row>
            </Container>
        </div>
    );
}

export default GettingStarted;