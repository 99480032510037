import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

function Documentation() {
    return (
        <div id="documentation" className="page">
            <Container>
                <h1>Documentation</h1>
                <p>We're still working on this. For now, <Link to="https://discord.com/invite/4QYv3vMGxS">join our Discord server</Link> to ask questions and learn from other BtoA users!</p>
            </Container>
        </div>
    );
}

export default Documentation;