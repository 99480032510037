import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import './Footer.css';
import NavigationLinks from "./NavigationLinks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Footer() {
    return (
        <div id="footer">
            <Container>
                <h4>Arnold for Blender</h4>
                <Row>
                    <Col>
                        <h5>Get Started</h5>
                        <NavigationLinks />
                    </Col>
                    <Col className="social">
                        <h5>Social</h5>
                        <Link to="https://discord.com/invite/4QYv3vMGxS" target="_blank"><FontAwesomeIcon icon={faDiscord} fixedWidth /> Discord</Link>
                        <Link to="https://www.instagram.com/arnoldforblender" target="_blank"><FontAwesomeIcon icon={faInstagram} fixedWidth /> Instagram</Link>
                        <Link to="https://www.youtube.com/channel/UCl946SP7vsvgMcaLFh9wMNw" target="_blank"><FontAwesomeIcon icon={faYoutube} fixedWidth /> YouTube</Link>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Footer;