import { Button } from 'react-bootstrap';
import './WelcomeBanner.css';

function WelcomeBanner() {
    return (
        <div id="home-banner">
            <div className='banner-content'>
                <h1>A community-developed Blender add-on for the Arnold renderer.</h1>
                <Button href="#getting-started">Learn More</Button>
            </div>
            <video src="ipr_render.mp4" autoPlay={true} loop={true} muted={true}></video>
        </div>
    );
}

export default WelcomeBanner;