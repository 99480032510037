import { Link } from "react-router-dom";

function NavigationLinks() {
    return (
        <div class="links">
            <Link to="/download">Download</Link>
            <Link to="https://github.com/lunadigital/btoa" target="_blank">GitHub</Link>
            <Link to="/docs">Docs</Link>
        </div>
    );
}

export default NavigationLinks;