import { Link } from 'react-router-dom';
import './Navigation.css';
import NavigationLinks from './NavigationLinks';

function Navigation() {
    return (
        <div className='navbar-container'>
            <div className="navbar">

                <div class="logo">
                    <Link to="/">
                        <img src="logo.svg"></img>
                    </Link>
                </div>
                
                <NavigationLinks />

            </div>
        </div>
    );
}

export default Navigation;